<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010">	
			<!-- 素材 -->
			<div class="padd30 ">
				<Material :list="list"></Material>
				<loadMore :state="state" loading_icon></loadMore>
			</div>
		</div>
		
		<div class="center paddt54 paddb15">
			<el-pagination background  class="center "  @current-change="handleCurrentChange"
			    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
			</el-pagination>
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'Mymateria',
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				size: 10, 
				maxnum:'' ,//总条数
			}
		},
		mounted() {
			
			this.getlist();
		},
		methods: {
			 getlist(){			 
			 	if (this.loading || this.state > 1) return;
			 	let page = parseInt(this.page) + 1;
			 	this.loading = true;
			 	this.state = 1;
			 	let size = 10;
			 	this.$req({
			 		method: 'get',
			 		data: {
			 			self:1,
						type:'source',
			 			page: page,
			 			size: size
			 		},
			 		url: '/api/course',
			 		success: res => {
			 			console.log(res)
			 			let list = res;
			 			this.list = list;
			 			if (list.length == size) {
			 				this.state = 1;
			 			} else if (list.length > 0) {
			 				this.state = 2;
			 			} else if (this.list.length == 0) {
			 				this.state = 3;
			 			}
			 			this.loading = false;
			 		},
			 		fail: error => {
			 			if (this.list.length == 0) {
			 				this.state = 3;
			 			} else {
			 				this.state = 2;
			 			}
			 			this.loading = false;
			 		}
			 	});
				
				this.$req({
					method: 'get',
					data: {
						self:1,
						type:'source',
						show_page:1,
						
					},
					url: '/api/course',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			 },
			 handleCurrentChange(val) {
			    this.page = val;
			    this.list = [];
			    this.loading = false;
			    this.getlist();
			     document.documentElement.scrollTop = document.body.scrollTop = 400;
			 },
		}
	}
</script>

<style lang="less" scoped>
	.title-item {
		padding: 0 30px;
	}
	
	.title-item:hover {
		cursor: pointer;
		color: #010085;
	}
	
	.img_01 {
		width: 164px;
		height: 122px;
	}
	
	.list-item {
		border: 1px solid #DCFF03;
		border: 1px solid #FFFFFF;
	}
	
	.list-item:hover {
		cursor: pointer;
		border: 1px solid #DCFF03;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
	}
	
	.list-item-01 {
		width: 283px;
		border: 1px solid #E6E6E6;
		border-radius: 8px;
		
		.content {
			height: 58px;
		}
	}
	
	.list-item-01:nth-child(3n) {
		margin-right: 0;
	}
	
	.list-item-01:hover {
		border: 1px solid #DCFF03;
		cursor: pointer;
	}
	
	.list-item-01:hover .s-content {
		color: #010085;
	}
	
	.list-item-pic-01 {
		width: 285px;
		height: 220px;
		border-radius: 8px 8px 0px 0px;
	}
	
	.label-item {
		padding: 0 9px;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 4px;
	}
	
	.label-item-01 {
		padding: 0 9px;
		height: 28px;
		background: #F7F7F7;
		border-radius: 4px;
	}
	
	.de_see {
		width: 20px;
		height: 20px;
	}
	
	.slist-item-pic {
		width: 285px;
		height: 220px;
	}
</style>
